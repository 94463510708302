import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { ScreenContainer } from '../../screens/styles'
import '../../styles/transitions/c_horizontal.css'
import '../../styles/transitions/c_horizontal2.css'
import '../../styles/transitions/fade.css'
import {
  ButtonArrow,
  ButtonSpan,
  ExitButton,
  Image,
  ImagePreview,
  ImagePreviewFooter,
  ImageViewerCon,
  LottiePreview,
  LottiePreviewCon,
  Svg,
  Video
} from './StyledLightbox'

const LIGHTBOX = React.memo(
  ({ escapeViewer, imageSelector, card }) => {
    const { media, type, id } = card
    const [lottie, setLottie] = useState(null)

    const route = '/project'

    useEffect(() => {
      if (/lottie/.test(type) && media) {
        (async () => {
          const res = await axios.get(media)
          const json = res.data
          setLottie(json)
        })()
      }
    }, [type, media])

    useEffect(() => {
      const exitFullScreen = (e) => {
        const { keyCode } = e
        switch (keyCode) {
          case 27:
            escapeViewer()
            break
          case 39:
            imageSelector('next')
            break
          case 37:
            imageSelector('previous')
            break
          default:
            break
        }
      }
      document.addEventListener('keydown', exitFullScreen, false)

      return () => {
        document.removeEventListener('keydown', exitFullScreen, false)
      }
    }, [id, imageSelector, escapeViewer])

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: lottie,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }

    return (
      <ScreenContainer $overflow='hidden'>
        <ImageViewerCon
          route={route}
          media={media}
        >
          <ImagePreview route={route}>
            <button type='project' onClick={() => imageSelector('previous')}>
              <span>‹</span>
            </button>
            <TransitionGroup component={null}>
              <CSSTransition classNames='fade' key={media} timeout={800}>
                <div>
                  {type === 'combo' || type === 'image'
                    ? (
                      <Image
                        route={route}
                        border='0'
                        src={media}
                      />
                      )
                    : type === 'combosvg' || type === 'svg'
                      ? (
                        <Svg
                          route={route}
                          border='0'
                          src={media}
                        />
                        )
                      : type === 'lottie'
                        ? (
                          <LottiePreviewCon className='lottieContainer'>
                            <LottiePreview
                              border='0'
                              style={{ maxWidth: '1000px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 0, zIndex: 10, width: '100%', maxHeight: '100%', height: 'auto' }}
                              options={defaultOptions}
                            />
                          </LottiePreviewCon>
                          )
                        : type === 'combovideo' || type === 'video'
                          ? (
                            <Video
                              route={route}
                              border='0'
                              src={media}
                            />
                            )
                          : null}
                </div>
              </CSSTransition>
            </TransitionGroup>
            <button className='close' type='project' onClick={escapeViewer}>
              <span>❌</span>
            </button>
            <button type='project' onClick={() => imageSelector('next')}>
              <span>›</span>
            </button>
          </ImagePreview>
          <ImagePreviewFooter
            route={route}
          >
            <ExitButton
              route={route}
              onClick={escapeViewer}
            >
              <ButtonSpan>exit full screen</ButtonSpan>
              <ButtonArrow>
                <span role='img' aria-label='exit'>
                  ❌
                </span>
              </ButtonArrow>
            </ExitButton>
          </ImagePreviewFooter>
        </ImageViewerCon>
      </ScreenContainer>
    )
  }
)

export default LIGHTBOX
