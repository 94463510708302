import Lottie from 'react-lottie'
import styled from 'styled-components/macro'
import { PAGE_CONTAINER } from '../../screens/styles'
import { FlexCol } from '../../styles/flex'

export const LoaderContainer = styled.div`
  ${PAGE_CONTAINER};
  ${FlexCol};
  height: 100vh;
  width: 100%;
  margin: auto;
  background-color: transparent;
  div{
    background-color: transparent;
  }

  @media (orientation: portrait) {
    height: 100%;
  }
`

export const LottieContainer = styled.div`
  grid-area:lottie;
border-radius: 10px;
  width: 100%;
  height: 100%;
  position: relative;
  ${FlexCol};
  background: #171717;
  pointer-events: none;
  *{
    pointer-events: none;
  }
  
  div {
    width:100%;
    height:100%;
    transform: none;
    position: absolute; 
  }

`

export const LottieContainer2 = styled(LottieContainer)`
  width: 30vw;
  height: 30vw;

  @media (max-aspect-ratio: 33 / 22) {
    width: 39vw;
    height: 39vw;
  }

  @media (orientation: portrait) {
    position: absolute;
    top: 0;
    margin-top: 0;
    width: auto;
    height: 60%;
  }
  @media (max-aspect-ratio: 9 / 16) {
    width: auto;
    height: 45%;
  }

  @media (max-aspect-ratio: 9 / 18) {
    width: auto;
    height: 50%;
  }
`

export const LottieContainerLoader = styled(LottieContainer)`
  width: 10vw;
  height: 10vw;
  transform: none;
  background-color: transparent;

  @media (orientation: portrait) {
    transform: none;
    margin: auto;
    position: relative;
  }
`

export const LottieImageLoader = styled(LottieContainer)`
  background: transparent;
  ${FlexCol};
  position: ${({ $head }) => ($head ? 'absolute' : 'relative')};
  width: 20%;
  height: auto;
  margin: auto;
  transform: none;
  opacity: ${({ $loaded }) => ($loaded ? 0 : 1)};
  display: ${({ $loaded }) => ($loaded ? 'none' : 'static')};

  @media (orientation: portrait) {
    position: absolute;
    top: 40%;
    left: 40%;
    width: 20%;
    height: auto;
  }
`

export const LottieAnimation = styled(Lottie)`
  ${FlexCol};
`
