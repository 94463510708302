import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { ThemeProvider } from 'styled-components/macro'
import LIGHTBOX from '../components/lightbox/Lightbox'
import META_PROJECT from '../components/meta/MetaProject'
import PROJECT_PROGRESSBAR from '../components/project/ProgressBar'
import PROJECT_CONTAINER from '../components/project/ProjectGrid'
import { DatabaseContext } from '../contexts/DatabaseContext'
import { ProjectContext } from '../contexts/ProjectContext'
import { ProjectScrollContainer } from './styles'

const initialCard = { title: null, media: null, type: null, id: null }

const PROJECT_PAGE = React.memo(
  ({ params, orientation, height, width, scrolled }) => {
    const [marginBottom, setMarginBottom] = useState(0)
    const [scrollAmount, setScroll] = useState(0)
    const [card, setCard] = useState(initialCard)
    const [viewer, setViewer] = useState(false)
    const [theme, setTheme] = useState({})

    const { databaseContext } = useContext(DatabaseContext)
    const { dispatchProject } = useContext(ProjectContext)

    const { id } = useParams()
    const project = databaseContext.projects.find(
      (project) => project.meta.name === id
    )

    useEffect(() => {
      if (project) {
        dispatchProject({ type: 'LOAD_PROJECT', value: project })
      }
    }, [dispatchProject, project])

    const meta = project ? project.meta : null
    const cards = project ? project.cards : null
    const fullname = project ? project.meta.fullname : null

    useEffect(() => {
      if (project) {
        if (params) {
          const cardId = params.get('card')
          const card = document.getElementById(cardId)
          const projectscrollcontainer = document.getElementById(
            'projectscrollcontainer'
          )
          if (card) {
            card.scrollIntoView()
            projectscrollcontainer.scrollBy(0, -200)
          }
        }

        setTheme({
          project: {
            bg: meta.themeBg,
            color: meta.themeColor,
            border1: meta.themeBorder1,
            border1Filled: meta.themeBorder1Filled,
            border2: meta.themeBorder2,
            border2Filled: meta.themeBorder2Filled
          }
        })
      }
    }, [project, params, meta])

    const route = '/project'

    const refScroll = useRef(null)

    const update = useCallback(() => {
      if (refScroll.current) {
        const projectScrollContainer = refScroll.current
        const margin = projectScrollContainer.clientWidth * 0.02
        const top = projectScrollContainer.scrollTop
        setScroll(top)
        setMarginBottom(margin)
      }
    }, [])

    const updateScroll = () => {
      if (refScroll.current) {
        const projectScrollContainer = refScroll.current
        const top = projectScrollContainer.scrollTop
        setScroll(top)
      }
    }

    const escapeViewer = useCallback(() => {
      setCard(initialCard)
      setViewer(false)
    }, [])

    const toggleLightbox = useCallback(
      (e) => {
        if (e.target.id) {
          const id = parseInt(e.target.id.match(/\d+/)[0], 10)
          const inusecards = cards.filter((card) => card.$inuse)
          const regularcards = cards.filter((card) => !card.$inuse)
          const arr = /inuse-/.test(e.target.id) ? inusecards : regularcards
          const card = arr[id]
          if (card) {
            setViewer(true)
            setCard({ ...card })
          } else {
            setCard(initialCard)
            setViewer(false)
          }
        }
      },
      [cards]
    )

    const imageSelector = useCallback(
      (direction) => {
        if (viewer) {
          const id = parseInt(card.id.match(/\d+/)[0], 10)
          const arr = cards.filter((card) => !card.$inuse)
          let newId
          let newCard

          const checkCard = (id, direction) => {
            if (direction === 'next') {
              let newId
              if (id < arr.length - 1) {
                newId = id + 1
              } else {
                newId = 0
              }
              newCard = arr[newId]
            } else {
              if (id === 0) {
                newId = arr.length - 1
                newCard = arr[newId]
              } else {
                newId = id - 1
                newCard = arr[newId]
              }
            }
            setCard({ ...newCard })
          }
          checkCard(id, direction)
        }
      },
      [card.id, cards, viewer]
    )

    useEffect(() => {
      update()
    }, [update])

    useEffect(() => {
      const orientationchange = () => {
        update()
      }
      window.addEventListener('orientationchange', orientationchange)

      return () =>
        window.removeEventListener('orientationchange', orientationchange)
    }, [toggleLightbox, update])

    useEffect(() => {
      const resize = (e) => {
        update()
        if (orientation === 'portrait') {
          toggleLightbox(e)
        }
      }
      window.addEventListener('resize', resize)

      return () => window.removeEventListener('resize', resize)
    }, [update, toggleLightbox, orientation])

    const redirect = params
      ? params.get('card') && params.get('ts')
        ? {
            pathname: `/project/${project.meta.name}`,
            search: `?card=${params.get('card')}`
          }
        : params.get('ts')
        ? {
            pathname: `/project/${project.meta.name}`
          }
        : null
      : null

    return (
      <ThemeProvider theme={theme}>
        {redirect && project ? (
          <Redirect to={redirect} />
        ) : !project ? (
          <Redirect to='/404' />
        ) : (
          <>
            <META_PROJECT meta={meta} />
            <LIGHTBOX
              projectName={fullname}
              projecttype={project.projectType}
              imageSelector={imageSelector}
              toggleLightbox={toggleLightbox}
              escapeViewer={escapeViewer}
              card={card}
              route={route}
              viewer={viewer}
            />

            <ProjectScrollContainer
              onScroll={updateScroll}
              ref={refScroll}
              id='projectscrollcontainer'
              route={route}
              $overflow={viewer ? 'hidden' : 'scroll'}
              projecttype={project.projectType}
            >
              {card.id !== null ? null : (
                <PROJECT_PROGRESSBAR
                  projecttype={project.projectType}
                  scrollAmount={scrollAmount}
                />
              )}
              <PROJECT_CONTAINER
                marginBottom={marginBottom}
                orientation={orientation}
                height={height}
                width={width}
                scrolled={scrolled}
                toggleLightbox={toggleLightbox}
                project={project}
              />
            </ProjectScrollContainer>
          </>
        )}
      </ThemeProvider>
    )
  }
)

export default PROJECT_PAGE
