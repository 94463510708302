const theme = (projectContext, isLoading) => {
  return {
    basic: {
      bg: '#1e1e1e',
      bgActive: '#FFC844',
      color: '#fff',
      border1: '1px solid rgba(255, 255, 255, 0.2)',
      border1Filled: '1px solid rgba(255, 255, 255, 1)',
      border2: '1px solid rgba(255,255,255,0.2)',
      border2Filled: '1px solid rgba(255,255,255,1)'
    },
    404: {
      bg: '#c4c4c4',
      bgActive: '#FFC844',
      color: '#000',
      border1: '1px solid rgba(255, 255, 255, 0.2)',
      border1Filled: '1px solid rgba(255, 255, 255, 1)',
      border2: '1px solid rgba(255,255,255,0.2)',
      border2Filled: '1px solid rgba(255,255,255,1)'
    },
    project: !isLoading && projectContext
      ? {
          bg: projectContext.meta.themeBg,
          color: projectContext.meta.themeColor,
          border1: projectContext.meta.themeBorder1,
          border1Filled: projectContext.meta.themeBorder1Filled,
          border2: projectContext.meta.themeBorder2,
          border2Filled: projectContext.meta.themeBorder2Filled
        }
      : {
          bg: '#1e1e1e',
          color: '#fff',
          border1: '1px solid rgba(255, 255, 255, 0.2)',
          border1Filled: '1px solid rgba(255, 255, 255, 1)',
          border2: '1px solid rgba(129,166,208,0.2)',
          border2Filled: '1px solid rgba(129,166,208,1)'
        }
  }
}

export default theme
