import { css } from 'styled-components/macro'

export const Flex = css`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const FlexCol = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const FlexColStart = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

export const FlexColEnd = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`

export const FlexColAround = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`

export const FlexColBetween = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

export const FlexRow = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const FlexRowAround = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`

export const FlexRowBetween = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const FlexRowStart = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

export const FlexRowEnd = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`
