import React, { createContext, useCallback, useReducer } from 'react'

const initialState = {}

export const ProjectContext = createContext({})

export const ProjectContextProvider = (props) => {
  const reducer = useCallback((state, action) => {
    switch (action.type) {
      case 'LOAD_PROJECT':
        return action.value
      default:
        alert('ERROR in projectcontext reducer')
        break
    }
  }, [])

  const [projectContext, dispatchProject] = useReducer(
    reducer,
    initialState
  )

  return (
    <ProjectContext.Provider
      value={{
        projectContext,
        dispatchProject
      }}
    >
      {props.children}
    </ProjectContext.Provider>
  )
}
