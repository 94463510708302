import styled from 'styled-components/macro'
import { FlexRowBetween } from '../../styles/flex'

export const BuyMe = styled.a`
  width: 100%;
  grid-area: buyMe;
  padding:0 var(--global-padding1);
  ${FlexRowBetween};
  border-radius:10px;
  user-select: none;

  &:hover {
    background-color: #252525;
  }
`
