const newOrientation = () => {
  let isPortrait
  let isLandscape
  let isTablet
  const test =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  if (test) {
    const orientation = window.screen.orientation || window.orientation
    const screenHeight = window.screen.height
    const screenWidth = window.screen.width
    isPortrait =
      screenHeight > screenWidth || orientation.type === 'portrait-primary'
    isLandscape = orientation.type === 'landscape-primary'
  } else {
    const innerHeight = window.innerHeight
    const innerWidth = window.innerWidth
    isPortrait = innerHeight > innerWidth
    isLandscape = innerHeight <= innerWidth
    isTablet = innerHeight >= (innerWidth / 33) * 22
  }
  // TODO Fix this tablet thing
  if (isPortrait) {
    return { orientation: 'portrait', tablet: false }
  } else if (isLandscape && isTablet) {
    return { orientation: 'landscape', isTablet: true }
  } else if (isLandscape) {
    return { orientation: 'landscape', isTablet: false }
  } else {
    console.log('ERROR CONTEXt')
  }
}

export default newOrientation
