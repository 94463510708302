import React from 'react'
import FOOTER_LEFT from './FooterLeft'
import FOOTER_RIGHT from './FooterRight'
import { Footer } from './styles'

const FOOTER = () => {
  return (
    <Footer>
      <FOOTER_LEFT />
      <FOOTER_RIGHT />
    </Footer>
  )
}

export default FOOTER
