import React from 'react'
import { CheckOutOther } from './styles'

const CHECK_OUT_OTHER = React.memo(({ orientation }) => {
  return (
    <CheckOutOther
      className='grid-item'
    >
      <h2>{`${orientation === 'landscape' ? 'check out' : ''} my other projects`}</h2>
      <h2>↘</h2>
    </CheckOutOther>
  )
})
export default CHECK_OUT_OTHER
