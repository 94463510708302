import React, { useContext, useRef } from 'react'
import HOME_CARD from '../../components/home/HomeCard'
import { DatabaseContext } from '../../contexts/DatabaseContext'
import { HomeProjectsGrid } from './styles'

const HOME_PROJECTS_GRID = () => {
  const { databaseContext } = useContext(DatabaseContext)

  const refGrid = useRef(null)

  return (
    <HomeProjectsGrid
      gridArea='projects'
      ref={refGrid}
    >
      {databaseContext.projects.map((project, i) => (
        <HOME_CARD
          id={`project${i + 1}`}
          gridArea={`project${i + 1}`}
          classes='grid-item grid-square'
          project={project}
          key={i}
          toggleLightbox={null}
          card={project.intro}
        />
      ))}
    </HomeProjectsGrid>

  )
}

export default HOME_PROJECTS_GRID
