import React from 'react'
import HOME_CARD_OVERFLOW from '../home/HomeCardOverflow'
import { CardImage } from './styles'

const CARD_IMG = React.memo(
  ({
    props: {
      projecttype,
      toggleOverflow,
      imageLoader,
      $loaded,
      card,
      url,
      route
    }
  }) => {
    const { media } = card

    return (
      <>
        <CardImage
          projecttype={projecttype}
          $loaded={$loaded}
          onLoad={imageLoader}
          src={media}
        />
        {
          route === '/'

            ? <HOME_CARD_OVERFLOW
                projecttype={projecttype}
                url={url}
                card={card}
                toggleOverflow={toggleOverflow}
              />
            : null
        }
      </>
    )
  }
)

export default CARD_IMG
