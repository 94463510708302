import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import { DatabaseContextProvider } from './contexts/DatabaseContext'
import { ProjectContextProvider } from './contexts/ProjectContext'
import { WindowContextProvider } from './contexts/WindowContext'
import * as serviceWorker from './serviceWorker'
import './styles/index.css'

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <WindowContextProvider>
        <DatabaseContextProvider>
          <ProjectContextProvider>
            <App />
          </ProjectContextProvider>
        </DatabaseContextProvider>
      </WindowContextProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)

serviceWorker.unregister()
