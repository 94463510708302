import { useContext, useCallback } from 'react'
import { WindowContext } from '../contexts/WindowContext'

const useMetaViewportUpdate = () => {
  const {
    windowContext: {
      orientation: { orientation }
    }
  } = useContext(WindowContext)

  const updateMetaViewport = useCallback(() => {
    const width = window.innerWidth
    const height = window.innerHeight
    const metaViewport = document.querySelector('meta[name=viewport]')
    if (orientation === 'portrait') {
      document.documentElement.style.setProperty('overflow', 'auto')
      metaViewport.setAttribute(
        'content',
        `width=${width}, height=${height}, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0`
      )
    } else {
      metaViewport.setAttribute(
        'content',
        'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
      )
    }
  }, [orientation])

  const onOrientationChange = useCallback(() => {
    updateMetaViewport()
    // eslint-disable-next-line
  }, [orientation, updateMetaViewport])

  const onMount = useCallback(() => {
    updateMetaViewport()
  }, [updateMetaViewport])

  return { onOrientationChange, onMount }
}

export default useMetaViewportUpdate
