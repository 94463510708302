import React, { useEffect, useState } from 'react'
import { ProgressBar, ProgressBarCon } from './styles'

const PROJECT_PROGRESSBAR = React.memo(({ projecttype, scrollAmount }) => {
  const [progress, setProgress] = useState(0)

  const route = '/project'

  useEffect(() => {
    const height = window.innerHeight
    const navbar = document.querySelector('nav')
    const navbarheight = navbar.clientHeight
    const pageContainer = document.getElementById('pagecontainer')
    const scrollbarHeight = pageContainer.clientHeight
    const scrollMax = scrollbarHeight + navbarheight - height
    const progress = (scrollAmount / scrollMax) * 100
    setProgress(progress)
  }, [scrollAmount])

  return (
    <ProgressBarCon>
      <ProgressBar projecttype={projecttype} route={route} progress={progress} />
    </ProgressBarCon>
  )
})

export default PROJECT_PROGRESSBAR
