import React from 'react'
import REFERENECS_QUOTE from './ReferencesQuote'
import { References } from './styles'

const REFERENCES = () => {
  return (
    <References>
      <REFERENECS_QUOTE />
    </References>
  )
}

export default REFERENCES
