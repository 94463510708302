import React from 'react'
import LINKS from '../../utils/externalLinks'
import { FooterRight } from './styles'

const FOOTER_RIGHT = () => {
  return (
    <FooterRight>
      <div>
        <h3>services</h3>
        <ul>
          <li>type design</li>
          <li>lettering</li>
          <li>graphic design</li>
          <li>branding</li>
          <li>consulting</li>
        </ul>
      </div>
      <div>
        <h3>social</h3>
        <ul>
          <a href={LINKS.instagram} target='_blank' rel='noreferrer'><li>instagram</li></a>
          <a href={LINKS.facebook} target='_blank' rel='noreferrer'><li>facebook</li></a>
          <a href={LINKS.twitter} target='_blank' rel='noreferrer'><li>twitter</li></a>
          <a href={LINKS.behance} target='_blank' rel='noreferrer'><li>behance</li></a>
        </ul>
      </div>
    </FooterRight>
  )
}

export default FOOTER_RIGHT
