import React from 'react'
import HOME_CARD_OVERFLOW from '../home/HomeCardOverflow'
import { CardSvg } from './styles'

const CARD_SVG = React.memo(
  ({
    props: {
      projecttype,
      route,
      toggleOverflow,
      $loaded,
      imageLoader,
      card,
      url
    }
  }) => {
    const { media } = card

    return (
      <>
        <CardSvg
          $loaded={$loaded}
          route={route}
          onLoad={imageLoader}
          src={media}
        />
        <HOME_CARD_OVERFLOW
          projecttype={projecttype}
          url={url}
          card={card}
          toggleOverflow={toggleOverflow}
        />
      </>
    )
  }
)

export default CARD_SVG
