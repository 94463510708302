import SVG from 'react-inlinesvg'
import styled, { css } from 'styled-components/macro'
import { FILTER } from '../../../src/styles/colorMixin'

export const CARD_IMAGE_CONTAINER = () => css`
  z-index: 3;
  filter: ${({ $overflow }) => ($overflow ? 'blur(0.27vw)' : 'none')};
  overflow: hidden;
  cursor:  pointer;
`

export const CARD_MEDIA = () => css`
  object-fit: cover;
  opacity: ${({ $loaded }) => ($loaded ? 1 : 0)};
  pointer-events: none;
  cursor: pointer;
`

// TODO ADD SMALL CLASS NAME TO HOME CARDS

export const HomeCard = styled.div`
  background-color: #171717;
  grid-area: ${({ gridArea }) => gridArea};
  overflow: hidden;
  user-select: none;

  img {
    ${FILTER};
  }

  &:hover {

    img {
      filter: none;
    }

    .cardOverflow {
      pointer-events: initial;
      position: absolute;
      opacity: 0.95;
    }
  }
`

export const Card = styled.div`
  padding-bottom: ${({ half }) => half ? '50%' : '100%'};
  overflow: hidden;
  cursor: ${({ main }) => main ? 'initial' : 'pointer'};

  img {
    filter: ${({ projecttype }) => projecttype === 'type design' ? 'grayscale(100%)' : 'none'};
  }
  
  &:hover {
    img {
      filter: none;
    }
  }
`

export const CardMediaContainer = styled.div`
  ${CARD_IMAGE_CONTAINER};
`

export const CardImage = styled.img`
  position: absolute !important;
  width: 100%;
  height: 100%;
  ${CARD_MEDIA};
  opacity: ${({ src }) => (src ? 1 : 0)};
  ${FILTER};

  &:hover {
    filter: none;
  }

`

export const CardSvg = styled(SVG)`
  position: absolute !important;
  width: calc(100% - var(--global-padding1) * 2);
  height: calc(100% - var(--global-padding1) * 2);
  transform: translateX(var(--global-padding1)) translateY(var(--global-padding1));
  ${CARD_MEDIA};
  opacity: ${({ src }) => (src ? 1 : 0)};
  overflow: visible;
`
