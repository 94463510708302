import SVG from 'react-inlinesvg'
import Lottie from 'react-lottie'
import styled, { css } from 'styled-components/macro'
import { FlexCol, FlexRow, FlexRowAround, FlexRowBetween, FlexRowEnd, FlexRowStart } from '../../styles/flex'

export const ImageViewerCon = styled.div`
  position: absolute;
  left: 0;
  width: 100vw;
  overflow: hidden;
  margin-top: var(--navheight);
  height: calc(100vh - var(--navheight));
  opacity: ${({ media }) => (media ? 1 : 0)};
  z-index: ${({ media }) => (media ? 4 : -1)};
  transition-duration: 0.4s;

  @media (orientation: portrait) {
    top: 0;
    height: calc(100% - var(--navheight));
  }
`

export const ImagePreview = styled.div`
  ${FlexRow};
  position: relative;
  width: 100vw;
  height: calc(100vh - var(--navheight) - 6vh);
  margin: auto;
  overflow: hidden;
  transition-duration: 0.4s;
  
  svg {
    padding:var(--global-padding2);
  }

  button,
  a {
    width: 12.5vw;
    position: absolute;
    top: calc((100vh - var(--navheight) - 6) / 2);
    user-select: none;

    &:first-of-type {
      left: 0;
    }

    &:last-of-type {
      right: 0;
    }

    span {
      font-size: 7.5vh;
      font-weight: 100;
    }
  }

  .close {
    display: none;
  }

  @media (orientation: portrait) {
    height:100%;
    
    div {
      width: 100%;
      height: 100%;
      overflow: visible;
    }

    .close {
      display: block;
    }

    button,
    a {
      top: auto;
      bottom: 0;
      height: 10vh;
      z-index: 10;
      
      &:first-child {
        left:var(--global-padding1);
        ${FlexRowStart};
      }

      &:last-child {
        right:var(--global-padding1);
        ${FlexRowEnd};
      }

      span {
        font-size: 5vh;
        font-weight: 100;
      }
    }
  }
`

export const ImagePreviewFooter = styled.div`
  ${FlexRowBetween};
  width: 100vw;
  height: 6vh;
  position: relative;
  bottom: 0;
  padding: 0 7vh;
  transition-duration: 0.4s;

  p {
    padding: 0 1.25vw 0 0;
    font-size: 1.33vh;
    font-weight: 700;
    opacity: 0.4;

    &:not(:first-of-type) {
      border: none;
      padding: 0 1.25vw;
      font-weight: 900;
    }
  }

  a {
    font-size: 3.4vh;
  }

  button {
    font-size: 3.4vh;
    position: absolute;
    opacity: 1;
  }

  @media (orientation: portrait) {
    ${FlexCol};
    padding: 0;
    height: 18%;
  }
`

export const Label = styled.div`
  width: auto;
  ${FlexRowStart};


  @media (orientation: portrait) {
    ${FlexRow};
    height: 50%;
    width: 100%;
    padding: 0;
  }
`

export const SharePanel = styled.div`
  ${FlexRowAround};
  height: 80%;

  button,
  a {
    position: relative;
    opacity: 0.4;
    height: 2vh;
    width: 2vh;
    margin: 0 1vw;

    &:hover {
      opacity: 1;
    }
  }

  @media (orientation: portrait) {
    width: 40vw;
    height: 100%;

    button,
    a {
      font-size: 4.5vh;
    }
  }
`

const IMAGE_STYLE = () => css`
  height: 100%;
  width: 75vw;
  margin: auto;
  position: absolute;
  top: 0;
  left: 12.5vw;
  object-fit: contain;
  transition-duration: 0.4s;

  @media (orientation: portrait) {
    object-fit: contain;
    height: calc(100% - 10vh);
    width: 100vw;
    left: auto;
  }
`

export const Image = styled.img`
  ${IMAGE_STYLE};
  
`

export const Video = styled.video`
  ${IMAGE_STYLE};
`

export const Svg = styled(SVG)`
  ${IMAGE_STYLE};
`

export const LottiePreviewCon = styled.div`
  height: 100%;
  width: 75vw;
  position:relative;
  top: 0;
  ${FlexCol};

  @media (orientation: portrait) {
    position: absolute;
    height: calc(100% - 10vh);
    width: 100vw;
  }
`

export const LottiePreview = styled(Lottie)`
  ${IMAGE_STYLE};
  width: 100%;
  position: relative;
  ${FlexCol};
`

export const ExitButton = styled.button`
  ${FlexRow};
  background-color: #000;
  cursor: pointer;
  opacity: 1;
  position: absolute;
  left: 44vw;
  height: 4vh;
  transform: translateY(-3vh);
  padding: 0 1vw;
  width: 22vh;
  z-index: 10;
  transition-duration: 0.4s;
  border-radius:10px;
  user-select: none;
  

  span {
    font-size: 1.5vh;
    margin: auto auto auto 1.25vh;
    transition-duration: 0.4s;
  }

  &:focus {
    font-weight: bold;
  }

  &:hover {
    span {
      font-weight: 900;
    }
  }

  @media (max-aspect-ratio: 33 / 22) {
    height: 3.64vw;
  }

  @media (orientation: portrait) {
    display: none;
  }
`

export const ButtonSpan = styled.span`
  font-size: 1.3vw;
  white-space: nowrap;
  line-height: 1em;

  @media (max-aspect-ratio: 33 / 22) {
    font-size: 1.69vw;
  }

  @media (orientation: portrait) {
    font-size: 3vh;
  }

  @media (max-aspect-ratio: 9 / 18) {
    font-size: 2.5vh;
  } ;

`

export const ButtonArrow = styled(ButtonSpan)`
  margin-left: var(--pad);
`
