const LINKS = {
  instagram: 'https://www.instagram.com/cinketype/',
  facebook: 'https://www.facebook.com/cinketype/',
  twitter: 'https://twitter.com/cinketype',
  behance: 'https://www.behance.net/horacesilver17bea6',
  buyMe: 'https://www.buymeacoffee.com/cinketype'

}

export default LINKS
