import React from 'react'
import Lottie from 'react-lottie'
import styled from 'styled-components/macro'
import lottieMonitor from '../../assets/lottie/LottieMonitor.json'

const SvgMonitor = styled.div`
  width:420px;
  transform: translateX(30px) translateY(-25px);

  @media (max-width: 1280px) {
    width:320px;
  }
  

  @media (max-width: 768px) {
    position: absolute;
    width:218px;
    transform: translateX(var(--global-padding2)) translateY(-25px);
    z-index: 13;
  }
`

const LOTTIE_MONITOR = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: lottieMonitor
  }

  return (
    <SvgMonitor className='lottieContainer'>
      <Lottie options={defaultOptions} />
    </SvgMonitor>
  )
}

export default LOTTIE_MONITOR
