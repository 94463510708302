import { NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'
import { FlexRow, FlexRowBetween } from '../../styles/flex'

const NavBar = styled.div`
  position: fixed;
  z-index: 8;
  top: 0;
  width: 100%;
  height: var(--navheight);
  margin: auto;
  border-bottom: 10px black solid;
  user-select: none;
  background-color: #171717;
  ${FlexRow};

  nav {
    width: 100%;
    max-width: 1440px;
    ${FlexRowBetween};
  }

  * {
    background-color: transparent;
  }

  @media (max-width: 1480px) {
    padding: 0 var(--global-padding3);
  }

  @media (max-width: 768px) {
    border-bottom: 6px black solid;
  }
`

const Anchor = styled.a`
  opacity: 0.7;
  height: 100%;
  font-size: var(--anchor);
  ${FlexRowBetween};
  font-weight: 600;
  pointer-events: auto;
  cursor: ${({ href }) => (href ? 'pointer' : 'auto')};

  div span {
    cursor: pointer;
  }

  &:hover {
    opacity: 1;
    font-weight: 800;
  }
`
const Link2 = styled(NavLink)`
  ${FlexRow};
`

const Cinketype = styled.span`
  font-size: var(--logo);
  font-weight: 900;
  margin-left: 5px;
  transition: all 1s cubic-bezier(0.76, 0, 0.24, 1);
  margin-bottom: 0;
`

const Hamburger = styled.div`
  span {
    font-size: var(--logo);
    white-space: nowrap;
    transition: all 1s cubic-bezier(0.76, 0, 0.24, 1);
    font-weight: 300;
  }
`

const Bird = styled(Hamburger)`
  span {
    font-weight: 900;
  }
`

const Cart = styled.span`
  font-size: 1.3em;
  margin-right: 0.7em;
`

export default {
  NavBar,
  Anchor,
  Hamburger,
  Cinketype,
  Link2,
  Bird,
  Cart
}
