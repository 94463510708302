import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'
import { BG } from '../../styles/colorMixin'
import { FlexColBetween, FlexColStart, FlexRowStart } from '../../styles/flex'

export const CARD_OVERFLOW_STYLE = css`
  ${FlexColBetween};
  align-items: flex-start;
  padding: var(--global-padding1);
  position: absolute;
  width: 100%;
  height: 100%;
  ${BG};
  opacity: ${({ $active }) => $active ? 0.95 : 0};
  pointer-events: ${({ $active }) => $active ? 'initial' : 'none'};

  div {
    ${FlexColStart};
    align-items: flex-start;
    background-color: transparent;
  }
`

export const CardOverflow = styled.div`
  ${CARD_OVERFLOW_STYLE}
`

export const CardOverflowLink = styled(NavLink)`
  ${CARD_OVERFLOW_STYLE}
`

export const ProjectTitle = styled.h3`
  position: relative;
  font-size: var(--cardoverflowTitle);
  line-height: 1em;
  text-align: left;
  ${FlexRowStart};
  color: #171717;
`

export const ProjectDescription = styled.h4`
  width: 100%;
  font-size: var(--cardoverflowDesc);
  text-align: left;
  color: #171717;
  letter-spacing: 0;
  line-height: 2em;
`

export const Arrow = styled.span`
  font-size: var(--cardoverflowArrow);
  line-height: 1em;
  text-align: left;
  ${FlexRowStart};
  font-weight: 900;
  color: #171717;
`

const HOME_CARD_OVERFLOW = React.memo(({ url, projecttype, card }) => {
  const [active, setActive] = useState(false)

  const handleOnClick = () => {
    setActive(true)
  }

  useEffect(() => {
    return () => setActive(false)
  }, [])

  if (url) {
    return (
      <CardOverflowLink
        projecttype={projecttype}
        to={url} onClick={handleOnClick} $active={active}
        className='cardOverflow'
      >
        <div>
          <ProjectTitle>{card.title.toLowerCase()}</ProjectTitle>
          <ProjectDescription>{projecttype} | 2020</ProjectDescription>
        </div>
        <Arrow>
          ↘
        </Arrow>
      </CardOverflowLink>
    )
  } else {
    return (
      <CardOverflow
        projecttype={projecttype}
        onClick={handleOnClick} $active={active}
        className='cardOverflow'
      >
        <div>
          <ProjectTitle>{card.title.toLowerCase()}</ProjectTitle>
          <ProjectDescription>type design | 2020</ProjectDescription>
        </div>
        <Arrow>
          ↘
        </Arrow>
      </CardOverflow>
    )
  }
})

export default HOME_CARD_OVERFLOW
