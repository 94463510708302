import styled, { css } from 'styled-components/macro'
import { FlexCol } from './flex'

export const BG = css`
     background-color: ${({ projecttype }) => projecttype === 'type design' ? 'var(--base-yellow)' : 'var(--base-blue)'};
  `

export const LinkSocial = styled.a`
  margin: 15px;
  font-size:var(--linkSocial);
  z-index: 3;
  ${FlexCol};

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    transform: scale(1.3);
    opacity: 1;
  }

  @media (max-width: 768px) {
    margin: 15px;
    &:last-child {
      margin-right: 15px;
    }

  }

`

export const FILTER = css`
  filter: ${({ projecttype }) => projecttype === 'type design' ? 'grayscale(100%)' : 'none'};
  `
