import React, { createContext, useCallback, useReducer } from 'react'
import newOrientation from '../utils/detectOrientation'

export const WindowContext = createContext('')

export const WindowContextProvider = (props) => {
  const reducer = useCallback(
    (state, action = { type: null, action: null }) => {
      let orientation
      switch (action.type) {
        case 'ORIENTATION':
          orientation = newOrientation()
          return { ...state, orientation }
        case 'OVERFLOW':
          return { ...state, $overflow: action.value }
        case 'DIMENSIONS':
          return { ...state, height: action.height, width: action.width }
        default:
          return state
      }
    },
    []
  )

  const orientation = newOrientation()
  const initialState = {
    orientation,
    height: window.innerHeight,
    width: window.innerWidth,
    $overflow: false,
    // TODO revise
    pageColors: {
      '/contact': '#81a6d0',
      '/work': '#FFC844',
      '/': '#EF909A',
      '/project': '#FFC844',
      '/about': '#73bfbe',
      '/shop': '#fff',
      '/404': '#c4c4c4'
    }
  }

  const [windowContext, dispatch] = useReducer(reducer, initialState)

  return (
    <WindowContext.Provider
      value={{
        windowContext,
        dispatch
      }}
    >
      {props.children}
    </WindowContext.Provider>
  )
}
