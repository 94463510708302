import styled from 'styled-components/macro'
import {
  FlexCol,
  FlexColBetween,
  FlexColStart,
  FlexRowEnd,
  FlexRowStart
} from '../../styles/flex'

const ContactForm = styled.form`
  width: 100%;
  grid-area: contact;
  display: grid;
  grid-gap: var(--gap);
  grid-template-columns: 1fr 0.5fr 1fr;
  grid-template-areas: 'contactForm contactForm contactSend';
  background-color: transparent;

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: var(--row-contact) var(--row-contactSend);
    grid-template-areas:
      'contactForm contactForm'
      'contactSend  contactSend';
  }
`

const ContactFormGridArea = styled.div`
  grid-gap: var(--gap);
  width: 100%;
  grid-area: contactForm;
  border-radius: 10px;
  padding: calc(var(--global-padding1) * 0.75) var(--global-padding1);
`

const ContactSend = styled.div`
  grid-area: contactSend;
  ${FlexCol};
  position: relative;
  background-color: transparent;

  button {
    padding: var(--global-padding1);
    border-radius: 10px;
    font-size: var(--contactsend);
    background-color: ${({ sent }) =>
      sent === true
        ? '#73BFBE'
        : sent === 'loading'
        ? 'var(--base-yellow)'
        : sent === false
        ? '#E94646'
        : '#171717'};
    font-weight: 900;
    position: relative;
    width: 100%;
    height: 100%;
    opacity: ${({ sent }) => (sent === true ? 0 : 1)};
    pointer-events: ${({ sent }) => (sent === true ? 'none' : 'default')};
    transition: all 0.1s ease-out;
    user-select: none;

    span {
      margin-right: var(--global-padding3);
      font-size: var(--contactsend);
      color: ${({ sent }) => (sent === 'loading' ? '#171717' : '#fff')};
    }

    &:first-of-type {
      background-color: #73bfbe;
      opacity: 1;
      position: absolute;
      left: 0;
      top: 0;
      cursor: default;
    }
  }

  button:last-of-type:hover {
    span {
      color: ${({ sent }) =>
        sent === true || sent === false ? '#fff' : '#171717'};
    }

    background-color: ${({ sent }) =>
      sent === true
        ? '#73BFBE'
        : sent === 'loading'
        ? 'var(--base-yellow)'
        : sent === false
        ? '#E94646'
        : 'var(--base-yellow)'};
  }

  @media (max-width: 768px) {
    font-weight: 900;
    span {
      display: inline;
    }
  }

  @media (max-width: 768px) {
    ${FlexRowEnd};
    button {
      height: 100%;
      padding: 0 var(--global-padding1);
    }
  }
`

const ContactTitle = styled.h1`
  color: white;
  line-height: 1em;
  ${FlexRowStart};
  grid-area: contactTitle;
  margin-bottom: var(--global-padding2);
  pointer-events: none;
  user-select: none;
`

const ContactInputContainer = styled.div`
  ${FlexColBetween};
  ${FlexColStart};
  position: relative;
`

const InputCon = styled.div`
  ${FlexRowStart};
  width: 100%;
  opacity: ${({ value, error }) =>
    value && value.length > 0 && !error ? 1 : 0.4};
  outline: none;
  position: relative;
  z-index: 0;
  margin-bottom: 0.75em;

  &:hover {
    opacity: 1;
  }
`

const Input = styled.input`
  position: relative;
  z-index: 2;
  width: 75%;
  overflow: hidden;
  border: none;
  outline: none;
  height: 100%;
  font-size: var(--contactInput);
  font-weight: ${({ focused }) => (focused ? 'bold' : 'thin')};
  color: white;

  &::placeholder {
    color: #fff;
    opacity: 1;
  }

  @media (max-width: 768px) {
    width: 90%;
  }
`

const ErrorMessage = styled.span`
  ${FlexRowEnd};
  position: relative;
  display: ${({ error }) => (error ? 'block' : 'none')};
  width: ${({ error }) => (error ? 'auto' : 0)};
  color: ${({ error }) => (error ? '#E94646' : '#fff')};
  white-space: nowrap;
  font-weight: 900;
  height: 100%;
  pointer-events: none;
`

const ErrorMessageTextarea = styled(ErrorMessage)`
  ${FlexColStart};
  align-items: flex-end;
  line-height: calc(var(--contactTextarea) * 2);
  opacity: 0.4;

  @media (max-width: 768px) {
    margin-top: 0.5em;
  }
`

const TextCon = styled.div`
  margin-top: var(--global-padding3);
  ${FlexRowStart};
  align-items: flex-start;
  width: 100%;
  outline: none;
  position: relative;
`

const TextArea = styled.textarea`
  position: relative;
  display: block;
  overflow: scroll;
  height: 100%;
  width: 75%;
  border: none;
  line-height: 2em;
  font-weight: ${({ focused }) => (focused ? 'bold' : 'thin')};
  resize: none;
  outline: none;
  // TODO COLOR
  color: white;
  opacity: ${({ value, error }) =>
    value && value.length > 0 && !error ? 1 : 0.4};
  font-size: var(--contactTextarea);

  &::placeholder {
    font-size: var(--contactInput);
    color: #fff;
    opacity: 1;
  }

  &:hover {
    opacity: 1;

    & ~ ${ErrorMessageTextarea} {
      opacity: 1;
    }
  }

  @media (max-width: 768px) {
    width: 90%;
  }
`

const Counter = styled.span`
  top: 100%;
  color: ${({ $max }) => ($max ? '#E94646' : '#fff')};
  font-size: var(--contactTextarea);
  position: absolute;
  opacity: 0.4;
  pointer-events: none;
  user-select: none;
  left: 100%;
  transform: translateX(-100%) translateY(-100%);
}
`

export default {
  ContactForm,
  ContactFormGridArea,
  ContactSend,
  ContactTitle,
  ContactInputContainer,
  Counter,
  ErrorMessage,
  ErrorMessageTextarea,
  TextCon,
  TextArea,
  Input,
  InputCon
}
