import buyFonts from '../../utils/buyFonts'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { WindowContext } from '../../contexts/WindowContext'
import BUY_FUTURE_FONTS from '../buyFutureFonts/BuyFutureFonts'
import BUY_ME from '../buyMe/BuyMe'
import CARD from '../cards/Card'
import PROJECT_PREVIEW from '../cards/CardProjectPreview'
import CHECK_OUT_OTHER from '../checkOut/CheckOutOther'
import FOOTER from '../footer/Footer'
import GET_IN_CONTACT from '../getInContact/GetInContact'
import SOCIAL_BAR from '../social/SocialBar'
import PROJECT_CARDS from './ProjectCards'
import PROJECT_SUMMARY from './ProjectSummary'
import PROJECT_TITLE from './ProjectTitle'
import { ProjectGrid } from './styles'

const PROJECT_CONTAINER = React.memo(
  ({ marginBottom, toggleLightbox, project }) => {
    const [refHeight, setRefHeight] = useState(0)
    const route = '/project'

    const { windowContext: { orientation: { orientation } } } = useContext(WindowContext)
    const { intro, meta, summary, cards } = project

    const refWork = useRef(null)

    const update = useCallback(() => {
      if (refWork.current) {
        const workContainer = refWork.current
        const height = workContainer.clientHeight
        setRefHeight(height)
      }
    }, [])

    useEffect(() => {
      update()
    }, [update])

    return (
      <ProjectGrid
        height={refHeight}
        id='pagecontainer'
        marginBottom={marginBottom}
        route={route}
        projectName={project.meta.name}
        projecttype={project.projectType}
      >
        <PROJECT_TITLE
          meta={meta}
        />
        <CARD
          meta={meta}
          main
          toggleLightbox={null}
          card={intro}
        />
        <PROJECT_SUMMARY
          summary={summary}
        />
        <PROJECT_CARDS
          grid={project.grid}
          toggleLightbox={toggleLightbox}
          meta={meta}
          cards={cards}
          projecttype={project.projectType}
        />
        {
          buyFonts[project.meta.name] ? <BUY_FUTURE_FONTS meta={meta} /> : null
        }
        <CHECK_OUT_OTHER meta={meta} orientation={orientation} />
        <PROJECT_PREVIEW
          project={project}
          route={route}
          orientation={orientation}
        />
        <SOCIAL_BAR
          route='/project'
        />
        <BUY_ME meta={meta} orientation={orientation} />
        <GET_IN_CONTACT meta={meta} orientation={orientation} />
        <FOOTER />
      </ProjectGrid>
    )
  }
)

export default PROJECT_CONTAINER
