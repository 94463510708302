import React from 'react'
import { BuyMe } from './styles'
import buyFonts from '../../utils/buyFonts'

const BUY_FUTURE_FONTS = React.memo(({ meta }) => {
  return (
    <BuyMe
      $cardType='project'
      route='/project'
      bg={meta.themeBg}
      color={meta.themeColor}
      projectName={buyFonts[meta.name].name}
      className='grid-item'
      target='_blank' href={buyFonts[meta.name].link} rel='noreferrer'
    >
      <h2>buy it</h2>
      <h2>
        →
      </h2>
    </BuyMe>
  )
})

export default BUY_FUTURE_FONTS
