import React from 'react'
import { Helmet } from 'react-helmet-async'
import metaConstants from '../../constants/metaConstants'

const META_HOME = () => {
  return (
    <Helmet>
      <title>Cinketype</title>
      <meta property='title' content='Cinketype' />
      <meta property='og:title' content='Cinketype' />
      <meta itemProp='name' content='Cinketype' />

      <meta
        property='description'
        content={metaConstants.description}
      />
      <meta
        property='og:description'
        content={metaConstants.description}
      />
      <meta
        itemProp='description'
        content={metaConstants.description}
      />

      <meta
        property='image'
        content={metaConstants.img}
      />
      <meta
        property='og:image'
        content={metaConstants.img}
      />
      <meta property='og:image:width' content='600' />
      <meta property='og:image:height' content='600' />
      <meta property='og:image:type' content='image/jpeg' />
      <meta
        itemProp='image'
        content={metaConstants.img}
      />

      <meta
        property='keywords'
        content={metaConstants.keywords}
      />
      <meta
        property='og:keywords'
        content={metaConstants.keywords}
      />
      <meta property='og:site_name' content='Cinketype' />
    </Helmet>
  )
}

export default META_HOME
