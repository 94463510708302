import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import validateField from './utils/validateField'
import Styled from './styles'

const ContactInput = ({ orientation, input, sent }) => {
  const { name, placeholder } = input

  const [focused, setFocused] = useState(false)
  const [error, setError] = useState(null)
  const [value, setValue] = useState('')

  const checkField = useCallback(() => {
    const validationResult = validateField({ name, value }, orientation)
    if (validationResult) {
      setError(validationResult.errorMessage)
    } else if (error) {
      setError(null)
    }
  }, [error, name, orientation, value])

  useEffect(() => {
    if (sent !== '') {
      checkField()
    }

    setFocused(null)
    if (sent === true) {
      setValue('')
      setError(null)
    }
  }, [sent, checkField])

  const handleOnBlur = () => {
    checkField()
    setFocused(!focused)
  }

  const handleFocus = () => {
    setFocused(!focused)
    setError(null)
  }

  const handleOnChange = (e) => {
    setValue(e.target.value)
  }

  const inputProps = {
    placeholder,
    name,
    value,
    onChange: handleOnChange,
    onBlur: handleOnBlur,
    onFocus: handleFocus
  }

  const isMessage = name === 'message'

  if (isMessage) {
    return (
      <Styled.TextCon error={error}>
        <Styled.Counter $max={value.length === 300}>
          {`${value.length}/300`}
        </Styled.Counter>
        <Styled.TextArea {...inputProps} maxLength={300} rows={6} />
        {error && (
          <Styled.ErrorMessageTextarea
            data-testid='ErrorMessageTextarea'
            focused={focused}
            error={error}
          >
            {error}
          </Styled.ErrorMessageTextarea>
        )}
      </Styled.TextCon>
    )
  }

  return (
    <Styled.InputCon error={error}>
      <Styled.Input {...inputProps} type='text' />
      {error && (
        <Styled.ErrorMessage data-testid='ErrorMessage' error={error}>
          {error}
        </Styled.ErrorMessage>
      )}
    </Styled.InputCon>
  )
}

export default ContactInput

ContactInput.defaultProps = {
  orientation: 'landscape',
  input: {
    name: '',
    placeholder: ''
  },
  sent: ''
}

ContactInput.propTypes = {
  orientation: PropTypes.oneOf(['portrait', 'landscape']).isRequired,
  sent: PropTypes.oneOf(['', 'loading', true, false]).isRequired,
  input: PropTypes.shape({
    name: PropTypes.string,
    placeholder: PropTypes.string
  }).isRequired
}
