import React from 'react'
import { FooterLeft } from './styles'

const FOOTER_LEFT = () => {
  return (
    <FooterLeft>
      <p>designed by <strong>cinketype</strong></p>
      <p>development by <strong>Krisztián Kovács</strong></p>
      <p>font used is <strong>Cinke Sans</strong></p>
      <p>2020</p>
    </FooterLeft>
  )
}

export default FOOTER_LEFT
