import React, { useContext, useEffect, useRef, useState } from 'react'
import { DatabaseContext } from '../../contexts/DatabaseContext'
import getRandom from '../../utils/getRandom'
import HOME_CARD from '../home/HomeCard'
import { PreviewProjectsGrid } from '../home/styles'

const PROJECT_PREVIEW = React.memo(({ orientation, route, project }) => {
  const [otherProjects, setOtherProjects] = useState([])
  const [num, setNum] = useState(3)

  const { databaseContext } = useContext(DatabaseContext)

  useEffect(() => {
    if (orientation === 'landscape') {
      setNum(3)
    } else {
      setNum(2)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (orientation === 'landscape') {
      setNum(3)
    } else {
      setNum(2)
    }
  }, [orientation])

  useEffect(() => {
    const otherProjectsData = getRandom(
      route,
      project,
      num,
      databaseContext.projects
    )
    setOtherProjects(otherProjectsData)
  }, [num, route, databaseContext, project])

  const refPreviewCards = useRef(null)

  useEffect(() => {
    if (refPreviewCards.current) {
      const previevwCards = document.querySelectorAll('.previewCard')
      previevwCards.forEach((previewCard, i) => {
        previewCard.style.gridArea = `preview${i + 1}`
      })
    }
  }, [])

  return (
    <PreviewProjectsGrid gridArea='previewCards' ref={refPreviewCards}>
      {otherProjects.map((project, i) => (
        <HOME_CARD
          classes='grid-item homeCard-small grid-square'
          project={project}
          key={i}
          toggleLightbox={null}
          card={project.intro}
        />
      ))}
    </PreviewProjectsGrid>
  )
})

export default PROJECT_PREVIEW
