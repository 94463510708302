import emailRegExp from './emailRegExp'

const validateField = (field, orientation) => {
  const { value, name } = field

  const isError = () => {
    const isEmpty = value === ''

    if (isEmpty) {
      return 'empty'
    }

    const isEmail = name === 'email'
    const emailTest = emailRegExp.test(value)

    if (isEmail && !emailTest) {
      return 'email'
    }

    return false
  }

  const getErrorMessage = () => {
    const isPortrait = orientation === 'portrait'
    if (isPortrait) {
      return '!'
    }

    const errorType = isError()

    if (errorType === 'email') {
      return 'please provide a valid e-mail'
    } else {
      return `please fill the ${name} field`
    }
  }
  if (isError()) {
    return { errorMessage: getErrorMessage(), name }
  } else {
    return null
  }
}

export default validateField
