import React from 'react'
import Lottie from 'react-lottie'
import styled from 'styled-components/macro'
import lottieSzt from '../../assets/lottie/LottieSzt.json'

const SvgSzt = styled.div`
  position: relative;
  width:450px;
  transform: translateX(-85px) translateY(50px);
  z-index: 3;
  @media (max-width: 1024px) {
    transform: translateX(-150px) translateY(50px);
  }

  @media (max-width: 768px) {
    width:280px;
    position: absolute;
    transform: translateX(-70px) translateY(15px);
  }
`

const LOTTIE_SZT = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: lottieSzt,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return (
    <SvgSzt className='lottieContainer'>
      <Lottie options={defaultOptions} />
    </SvgSzt>
  )
}

export default LOTTIE_SZT
