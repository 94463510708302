import React from 'react'
import { ProjectTitle } from './styles'

const PROJECT_TITLE = React.memo(({ meta }) => {
  return (
    <ProjectTitle
      route='/project'
      className='grid-item'
    >
      {meta.fullname.toLowerCase()}
    </ProjectTitle>
  )
})

export default PROJECT_TITLE
