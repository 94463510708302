import React from 'react'
import { GetInContact } from './styles'

const GET_IN_CONTACT = () => {
  return (
    <GetInContact
      to='/#contact'
      className='grid-item'
    >
      <h2>get in contact</h2>
      <h2>
        →
      </h2>
    </GetInContact>
  )
}

export default GET_IN_CONTACT
