import React, { useContext, useEffect, useState, useCallback } from 'react'
import { useLocation } from 'react-router'
import { ProjectContext } from '../../contexts/ProjectContext'
import buyFonts from '../../utils/buyFonts'
import Styled from './styles'

const Anchor = () => {
  const {
    projectContext: { meta }
  } = useContext(ProjectContext)

  const [projectName, setProjectName] = useState('')

  const { pathname: route, hash } = useLocation()
  const isProjectRoute = /\/project\//.test(route)
  const isRoot = route === '/'

  const setAnchor = useCallback(() => {
    if (isProjectRoute) {
      setProjectName(meta.name)
    } else {
      setProjectName(null)
    }
  }, [isProjectRoute, meta.name])

  useEffect(() => {
    setAnchor()
  }, [meta, setAnchor])

  useEffect(() => {
    setAnchor()
  }, [route, setAnchor])

  const scrollToContact = useCallback(() => {
    if (isRoot) {
      const contactForm = document.getElementById('contact')
      contactForm.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      })
    }
  }, [isRoot])

  useEffect(() => {
    if (hash === '#contact' && isProjectRoute) {
      setTimeout(() => {
        scrollToContact()
      }, 1000)
    }
  }, [hash, isProjectRoute, scrollToContact])

  const futureFonts = buyFonts[projectName]

  const AnchorText = () =>
    futureFonts ? (
      <div>
        <Styled.Cart>🛒</Styled.Cart>
        <span>{`Buy ${futureFonts.name} on Future Fonts`}</span>
      </div>
    ) : isRoot ? (
      <div>
        <span>get in contact</span>
      </div>
    ) : (
      <span>{meta.fullname.toLowerCase()}</span>
    )

  return (
    <Styled.Anchor
      onClick={scrollToContact}
      target='_blank'
      href={futureFonts && futureFonts.link}
      rel='noreferrer'
    >
      <AnchorText />
    </Styled.Anchor>
  )
}

export default Anchor
