import React from 'react'
import { AboutParagraph, AboutText, AboutTitle } from './styles'

const ABOUT_TEXT = () => {
  return (
    <AboutText className='grid-item'>
      <AboutTitle>about</AboutTitle>
      <AboutParagraph>
        Hi, I’m Tibor Szikora, hungarian type designer. I’m specialised in
        producing custom typefaces and lettering. I&nbsp;have more than a decade
        of experience in graphic design, and branding. I’m also passionate about
        design education, and available for consultation too.

      </AboutParagraph>
    </AboutText>
  )
}

export default ABOUT_TEXT
