const buyFonts = {
  kass: {
    name: 'Kass',
    link: 'https://www.futurefonts.xyz/cinketype/kass'
  },
  crow: {
    name: 'Crow',
    link: 'https://www.futurefonts.xyz/cinketype/crow'
  },
  margaretneue: {
    name: 'Margaret',
    link: 'https://www.futurefonts.xyz/cinketype/margaret-neue?v=0.1'
  }
}

export default buyFonts
