import styled from 'styled-components/macro'
import { FlexRow, FlexRowEnd, FlexRowStart } from '../../styles/flex'

export const Social = styled.div`
  border-radius:10px;
  padding:0 var(--global-padding1);  
  width: 100%;
  grid-area: social;
  display: grid;
  grid-template-areas: "socialTitle socialTitle socialIcons";
  user-select: none;

  @media (max-width: 768px) {
    grid-template-areas: "socialIcons";
  }
`

export const SocialTitle = styled.h2`
  background-color: transparent;
  width: 100%;
  grid-area: socialTitle;
  ${FlexRowStart};

  @media (max-width: 768px) {
    display: none;
  }
`

export const SocialIcons = styled.div`
  ${FlexRowEnd};
  background-color: transparent;
  width: 100%;
  grid-area: socialIcons;
  
  @media(max-width:768px){
    ${FlexRow};  
  }
  
;
  
`
