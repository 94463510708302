import Navbar from './Navbar/Navbar'
import React, { useContext, useRef } from 'react'
import { Route, useLocation } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { WindowContext } from '../contexts/WindowContext'
import PAGES from '../screens/Screens'
import { ScreenContainer } from '../screens/styles'
import '../styles/app.css'
import '../styles/transitions/transitions.css'
import '../styles/transitions/transitions2.css'

const AppNavigator = () => {
  const {
    windowContext: {
      orientation: { orientation }
    }
  } = useContext(WindowContext)

  const route = useLocation().pathname
  const ref = useRef(null)

  return (
    <Route
      render={({ location }) => (
        <TransitionGroup>
          <CSSTransition
            classNames={
              orientation === 'portrait' ? 'transition2' : 'transition'
            }
            key={location.key}
            appear
            timeout={1000}
          >
            <ScreenContainer id='container' ref={ref} route={route}>
              <Navbar route={route} />
              <PAGES location={location} />
            </ScreenContainer>
          </CSSTransition>
        </TransitionGroup>
      )}
    />
  )
}

export default AppNavigator
