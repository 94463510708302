import React from 'react'
import LINKS from '../../utils/externalLinks'
import LINK_SOCIAL from './SocialLink'
import { Social, SocialIcons, SocialTitle } from './styles'

const SOCIAL_BAR = () => {
  return (
    <Social>
      <SocialTitle
        className='grid-item'
      >
        follow me for more
      </SocialTitle>
      <SocialIcons className='grid-item'>
        <LINK_SOCIAL
          to={LINKS.instagram}
          type='instagram'
        />
        <LINK_SOCIAL
          to={LINKS.facebook}
          type='facebook'
        />
        <LINK_SOCIAL
          to={LINKS.twitter}
          type='twitter'
        />
      </SocialIcons>
    </Social>
  )
}

export default SOCIAL_BAR
