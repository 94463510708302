import React from 'react'
import META_NOTFOUND from '../components/meta/MetaNotFound'
import { NotFound } from './styles'
import { ReactComponent as NotFoundSvg } from '../assets/svg/404.svg'

const NOT_FOUND = React.memo(() => {
  const route = '/404'
  return (
    <>
      <NotFound route={route}>
        <META_NOTFOUND />
        <NotFoundSvg />
      </NotFound>
    </>
  )
})

export default NOT_FOUND
