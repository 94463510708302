import React, { createContext, useCallback, useReducer } from 'react'
const initialState = null

export const DatabaseContext = createContext('')

export const DatabaseContextProvider = (props) => {
  const reducer = useCallback((state, action) => {
    switch (action.type) {
      case 'LOAD_PROJECTS':
        return {
          ...state,
          projects: [...action.projects],
          references: [...action.references]
        }
      default:
        alert('ERROR in DatabaseContext reducer')
        break
    }
  }, [])

  const [databaseContext, dispatchData] = useReducer(
    reducer,
    initialState
  )

  return (
    <DatabaseContext.Provider
      value={{
        databaseContext,
        dispatchData
      }}
    >
      {props.children}
    </DatabaseContext.Provider>
  )
}
