import styled, { css } from 'styled-components/macro'

export const HomeGrid = styled.div`
  background-color: #000;
  margin-top: var(--navheight);
  height: 100%;
  max-width: 1440px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows:
    var(--row-about) var(--row-references) var(--row-project)  var(--row-low) var(--row-contact) var(--row-footer);
  grid-gap: var(--gap);
  grid-template-areas:
    "about about about about about about"
    "references references references references references references"
    "projects projects projects projects projects projects"
    "social social social social social social"
    "contact contact contact contact contact contact"
    "footer footer footer footer footer footer";

  h3,
  div h3 {
    height: 100%;
  }

  *::selection {
    background-color: var(--base-yellow);
    color: #000;
  }
  

  @media (max-width: 1440px) {
    overflow-y: scroll;
  }

  @media (max-width: 768px) {

    grid-template-rows:
    var(--row-about) var(--row-references) var(--row-project)  auto var(--row-contact) var(--row-footer);
    grid-gap: var(--gap);
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "about about"
      "references references"
      "projects projects"
      "social social"
      "contact contact"
      "footer footer";
  }
`

const HOME_CARD_SMALL_TYPO = css`
  h3 {
    font-size: var(--small-cardoverflowTitle) !important;
  }

  h4 {
    font-size: var(--small-cardoverflowDesc);
  }

  span, a {
    font-size: var(--small-cardoverflowArrow);
  }
`

const HOME_CARD_TYPO = css`
  h3 {
    font-size: var(--cardoverflowTitle) !important;
  }

  h4 {
    font-size: var(--cardoverflowDesc);
  }

  span, a {
    font-size: var(--cardoverflowArrow);
  }
`

const HOME_PROJECT_GRID_STYLE = css`
  background-color: #000;
  width: 100%;
  grid-area: ${({ gridArea }) => gridArea};
  grid-gap: var(--gap);
  display: grid;
  position: relative;

  .homeCard-small {
    .cardOverflow {

      @media (max-width: 768px) {
        padding: var(--global-padding3);
      }

    }
  }
`

export const HomeProjectsGrid = styled.div`
  ${HOME_PROJECT_GRID_STYLE};
  grid-template-areas: "project1 project1 project1 project2 project2 project2"
    "project3 project3 project4 project4 project5 project5"
    "project6 project6 project6 project7 project7 project7"
    "project8 project8 project9 project9 project10 project10";
  grid-template-columns: repeat(6, 1fr);

  #project3, #project4, #project5, #project8, #project9, #project10 {
    ${HOME_CARD_SMALL_TYPO};
  }

  @media (max-width: 768px) {
    grid-template-areas: "project1 project1 project1 project1"
    "project2 project2 project2 project2"   
    "project3 project3 project4 project4"
    "project5 project5 project5 project5"
    "project6 project6 project6 project6"
    "project7 project7 project8 project8"
    "project9 project9 project9 project9"
    "project10 project10 project10 project10"
  ;
    grid-template-columns: repeat(4, 1fr);

    #project1, #project2, #project5, #project6, #project9, #project10 {
      ${HOME_CARD_TYPO};
    }

    #project3, #project4, #project7, #project8 {
      ${HOME_CARD_SMALL_TYPO};
    }


  }
`
export const PreviewProjectsGrid = styled.div`
  ${HOME_PROJECT_GRID_STYLE};
  grid-template-areas:"preview1 preview2 preview3";
  grid-template-columns: repeat(3, 1fr);
  ${HOME_CARD_SMALL_TYPO};

  @media (orientation: portrait) {
    grid-template-areas:"preview1 preview2";
    grid-template-columns: repeat(2, 1fr);
  }
`
