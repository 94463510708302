import React from 'react'
import Styled from './styles'
import PropTypes from 'prop-types'

const ContactSendButton = ({ orientation, onBlur, sent }) => (
  <Styled.ContactSend
    data-testid='contactSendButton'
    sent={sent}
    onBlur={onBlur}
  >
    <button disabled className='grid-item'>
      <span>sent</span>
    </button>
    <button type='submit' className='grid-item'>
      <span>{orientation === 'portrait' ? 'send ' : ''}→</span>
    </button>
  </Styled.ContactSend>
)

export default ContactSendButton

ContactSendButton.defaultProps = {
  onBlur: () => ({}),
  sent: null,
  orientation: 'landscape'
}

ContactSendButton.propTypes = {
  sent: PropTypes.oneOf(['', 'loading', true, false]).isRequired,
  onBlur: PropTypes.func.isRequired,
  orientation: PropTypes.oneOf(['portrait', 'landscape']).isRequired
}
