import React, { useEffect, useRef } from 'react'
import CARD from '../cards/Card'
import { Cards } from './styles'

const PROJECT_CARDS = React.memo(
  ({ meta, projecttype, cards, grid, toggleLightbox }) => {
    const refGrid = useRef(null)

    useEffect(() => {
      if (refGrid.current) {
        refGrid.current.style.gridTemplateAreas = grid

        const cards = Array.from(refGrid.current.childNodes)
        cards.forEach((card, index) => {
          card.style.gridArea = `card${index + 1}`
        })
      }
    }, [grid])

    return (
      <Cards ref={refGrid}>
        {cards.map((card, i) => (
          <CARD
            projecttype={projecttype}
            key={i}
            toggleLightbox={toggleLightbox}
            card={card}
            meta={meta}
          />
        ))}
      </Cards>
    )
  }
)

export default PROJECT_CARDS
