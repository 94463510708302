import React from 'react'
import LOTTIE_MONITOR from '../lottie/LottieMonitor'
import { AboutMonitor } from './styles'

const ABOUT_MONITOR = React.memo(() => {
  return (
    <AboutMonitor className='grid-item'>
      <LOTTIE_MONITOR />
    </AboutMonitor>
  )
})

export default ABOUT_MONITOR
