import React from 'react'
import Styled from './styles'

const CinketypeLogo = () => {
  return (
    <Styled.Link2 to='/'>
      <Styled.Bird>
        <span role='img' aria-label='bird'>
          🐣
        </span>
      </Styled.Bird>
      <Styled.Cinketype></Styled.Cinketype>
    </Styled.Link2>
  )
}

export default CinketypeLogo
