import styled, { css } from 'styled-components/macro'
import { BG } from '../../../src/styles/colorMixin'
import { FlexCol, FlexRowStart } from '../../styles/flex'
import buyFonts from '../../utils/buyFonts'

const GRID = ({ projectName }) => {
  if (buyFonts[projectName]) {
    const name = `buy${buyFonts[projectName].name}`
    return css`
      grid-template-areas:
    "projectTitle projectTitle projectTitle projectTitle "
    "mainCard mainCard mainCard mainCard "
    "summary summary summary summary "
    "cards cards cards cards"
    "${name} ${name} ${name} ${name}"
    "linkOther linkOther linkOther linkOther "
    "previewCards previewCards previewCards previewCards"
    "social social social social"
    "getInContact getInContact getInContact getInContact"
    "buyMe buyMe buyMe buyMe"
    "footer footer footer footer";
    `
  } else {
    return css`
      grid-template-areas:
    "projectTitle projectTitle projectTitle projectTitle "
    "mainCard mainCard mainCard mainCard "
    "summary summary summary summary "
    "cards cards cards cards"
    "linkOther linkOther linkOther linkOther "
    "previewCards previewCards previewCards previewCards"
    "social social social social"
    "getInContact getInContact getInContact getInContact"
    "buyMe buyMe buyMe buyMe"
    "footer footer footer footer";
    `
  }
}

const GRID_MOBILE = ({ projectName }) => {
  if (buyFonts[projectName]) {
    const name = `buy${buyFonts[projectName].name}`
    return css`
      grid-template-areas: 
    "projectTitle projectTitle"
    "mainCard mainCard"
    "summary summary"
    "cards cards"
    "${name} ${name}"
    "linkOther linkOther"
    "previewCards previewCards"
    "social social"
    "getInContact getInContact"
    "buyMe buyMe"
    "footer footer";
    `
  } else {
    return css`
      grid-template-areas: "projectTitle projectTitle"
    "mainCard mainCard"
    "summary summary"
    "cards cards"
    "linkOther linkOther"
    "previewCards previewCards"
    "social social"
    "getInContact getInContact"
    "buyMe buyMe"
    "footer footer";
    `
  }
}

const GRID_ROWS = ({ projectName }) => {
  if (buyFonts[projectName]) {
    return css`
      grid-template-rows: var(--row-low) var(--row-project) var(--row-summary) var(--row-project)
      var(--row-low) var(--row-low) var(--row-project) var(--row-low) var(--row-low) var(--row-low)  var(--row-footer);
    `
  } else {
    return css`
      grid-template-rows: var(--row-low) var(--row-project) var(--row-summary) var(--row-project)
      var(--row-low) var(--row-project) var(--row-low) var(--row-low) var(--row-low) var(--row-footer);
    `
  }
}

export const ProjectGrid = styled.div`
  background-color: #000;
  margin: var(--navheight) auto auto auto;
  height: auto;
  max-width: 1440px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  ${GRID_ROWS};
  grid-gap: var(--gap);
  ${GRID};

  *::selection {
    ${BG};
    color: #000;
  }

  .homeCard-small {
    .cardOverflow {
      h3 {
        font-size: var(--small-cardoverflowTitle);
      }

      h4 {
        font-size: var(--small-cardoverflowDesc);
        line-height:1.8em;
      }

      a {
        font-size: var(--small-cardoverflowArrow);
        line-height:1.8em;
      }
    }
  }

  h3,
  div h3 {
    height: 100%;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--gap);
    ${GRID_MOBILE}
  }
`

export const ProjectTitle = styled.h2`
  background-color: #171717;
  padding: 0 var(--global-padding1);
  ${FlexRowStart};
  width: 100%;
  grid-area: projectTitle;
  font-size: var(--projectTitle);
  border-radius: 10px;
`

export const ProjectSummary = styled.div`
  padding: var(--global-padding1) 0 var(--global-padding1) var(--global-padding1);
  width: 100%;
  grid-area: summary;
  border-radius:10px;

  div:first-child {
    display: grid;
    grid-template-columns: 0.8fr 2.2fr;
    width: 55%;
  }

  div div {
    padding-left: var(--global-padding1);
  }

  div p:nth-child(2n) {
    margin-top: var(--global-padding3);
  }

  * {
    color: #fff;
  }

  @media(max-width: 1200px) {
    ${FlexRowStart};

    div:first-child {
      width: 100%;
      padding-right: var(--global-padding1);
      grid-template-columns: auto 1fr;
      grid-template-rows: 1fr;

      div {
        ${FlexCol};
      }
    }
  }

  @media(max-width: 768px) {
    padding: var(--global-padding1);

    div div {
      padding-left: 0;

    }

    div h2 {
      padding-bottom: var(--global-padding3)
    }

    div:first-child {
      width: 100%;
      padding: 0;
      grid-template-columns: 1fr;
    }
  }

`

export const Cards = styled.div`
  width: 100%;
  display: grid;
  grid-area: cards;
  grid-gap: var(--gap);
  grid-template-columns: repeat(4, 1fr);
  background-color: #000;
`

export const ProgressBarCon = styled.div`
  height: var(--navheight);
  width: 100%;
  position: fixed;
  top: 0;
  transition: none;
  z-index: 20;
  pointer-events: none;
  background-color: transparent;
  div{
    background-color: transparent;
  }
`

export const ProgressBar = styled.div`
  height: 10px;
  transition: none;
  position: absolute;
  bottom: 0;
  width: 100vw;
  border-radius: 25px;

  &::after {
    content: "";
    position: absolute;
    width: ${({ progress }) => progress + 'vw'};
    top: 40%;
    height: 20%;
    background-color: ${({ projecttype }) => projecttype === 'type design' ? 'var(--base-yellow)' : 'var(--base-blue)'};
    z-index: 20;
  }

  @media (orientation: portrait) {
    height: 6px;
  }
`
