import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Lottie from 'react-lottie'
import HOME_CARD_OVERFLOW from '../home/HomeCardOverflow'

const CARD_LOTTIE = React.memo(
  ({
    props: {
      projecttype,
      toggleOverflow,
      imageLoader,
      $loaded,
      card,
      url,
      route
    }
  }) => {
    const { media } = card

    const [lottie, setLottie] = useState(null)

    useEffect(() => {
      if (media) {
        (async () => {
          const res = await axios.get(media)
          const json = res.data
          setLottie(json)
          imageLoader()
        })()
      }
    }, [imageLoader, media])

    const defaultOptions = {
      loop: true,
      autoplay: true,
      renderer: 'svg',
      animationData: lottie,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }

    const style = {
      width: '100%',
      height: '100%',
      position: 'absolute',
      padding: 'var(--global-padding1)',
      backgroundColor: 'transparent'
    }

    return (
      <>
        <Lottie
          style={style}
          options={defaultOptions}
          isClickToPauseDisabled
          $loaded={$loaded}

        />
        {
          route === '/'
            ? <HOME_CARD_OVERFLOW
                projecttype={projecttype}
                url={url}
                card={card}
                toggleOverflow={toggleOverflow}
              />
            : null
        }
      </>
    )
  }
)

export default CARD_LOTTIE
