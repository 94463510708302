import React from 'react'
import { Helmet } from 'react-helmet-async'

const META_PROJECT = React.memo(({ meta }) => {
  const { title, description, media, keywords } = meta
  const title2 = `Cinketype | ${title[0].toUpperCase()}${title.slice(1)}`
  return (
    <Helmet>
      <title>{title2}</title>
      <meta property='title' content={title2} />
      <meta property='og:title' content={title2} />
      <meta itemProp='name' content={title2} />

      <meta property='description' content={description} />
      <meta property='og:description' content={description} />
      <meta itemProp='description' content={description} />

      <meta property='image' content={media} />
      <meta property='og:image' content={media} />
      <meta property='og:image:width' content='600' />
      <meta property='og:image:height' content='600' />
      <meta property='og:image:type' content='image/jpeg' />
      <meta itemProp='image' content={media} />

      <meta property='keywords' content={keywords} />
      <meta property='og:keywords' content={keywords} />

      <meta property='og:site_name' content={title2} />
    </Helmet>
  )
})
export default META_PROJECT
