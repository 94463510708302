import React from 'react'
import CARD_IMG from './CardImage'
import CARD_LOTTIE from './CardLottie'
import CARD_SVG from './CardSvg'

const CARD_MEDIA = React.memo((props) => {
  const {
    card: { type }
  } = props

  switch (type) {
    case 'image':
      return <CARD_IMG props={props} />
    case 'svg':
      return <CARD_SVG props={props} />
    case 'lottie':
      return <CARD_LOTTIE props={props} />
    default:
      return <div>Something went wrong...</div>
  }
})

export default CARD_MEDIA
