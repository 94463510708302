import React from 'react'
import { useLocation } from 'react-router'
import lottiePageLoader from '../../assets/lottie/LottiePageLoader.json'
import { LoaderContainer, LottieAnimation, LottieContainerLoader } from './styles'

const LOTTIE_PAGELOADER = React.memo(() => {
  const route = useLocation().pathname

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: lottiePageLoader,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return (
    <LoaderContainer route={route}>
      <LottieContainerLoader className='lottieContainer' loader>
        <LottieAnimation loader options={defaultOptions} />
      </LottieContainerLoader>
    </LoaderContainer>
  )
})

export default LOTTIE_PAGELOADER
