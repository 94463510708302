const getRandom = (route, project, num, projects) => {
  let available
  if (route === '/project') {
    available = projects
      .filter((project2) => {
        return project2.rank !== project.rank
      })
      .map((project) => parseInt(project.rank, 10))
  } else {
    available = projects.map((project) =>
      parseInt(project.rank, 10)
    )
  }

  const arr = []
  let i = 0
  const max = Math.max(...available)

  const randomize = () => {
    while (i < num) {
      const random = Math.round(Math.random() * max)
      if (arr.indexOf(random) !== -1 || available.indexOf(random) === -1) {
        randomize()
      } else {
        arr.push(random)
        available.splice(available.indexOf(random), 1)
      }
      i++
    }
  }
  randomize()
  return arr.map((i) => projects[i])
}
export default getRandom
