import styled, { css } from 'styled-components/macro'

import { FlexCol, FlexColStart } from '../styles/flex'

export const PAGE_CONTAINER = css`
  scrollbar-width: none;
  min-height: 100vh;
  margin: auto;
  overflow-x: hidden;
  position: relative;

  @media (orientation: portrait) {
    min-height: 100%;
  }
`

export const ScreenContainer = styled.div`
  width: 100vw;
  height: 100vh;
  scroll-snap-type: y proximity;
  overflow-y: ${({ $overflow }) => $overflow};
  overflow-x: hidden;
  scrollbar-width: none;
  position: absolute;
  ${FlexColStart};
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;

  @media (orientation: portrait) {
    height: 100%;
    &::after {
      display: none;
    }
  }
`

export const NavigatorContainer = styled(ScreenContainer)`
  @media (min-width: 1440px) {
    overflow-y: scroll;
  }
`

export const ProjectScrollContainer = styled.div`
  ${PAGE_CONTAINER};
  width: 100vw;
  overflow: ${({ $overflow }) => $overflow};
  background-color: transparent;
`

export const NotFound = styled.div`
  max-width: 1440px;
  ${PAGE_CONTAINER};
  height: 100vh;
  width: 100vw;
  background-color: #171717;
  ${FlexCol};
  position: absolute;

  @media (orientation: portrait) {
    svg {
      width: 90%;
    }
  }
`
