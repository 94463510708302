import React, { useContext } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { Redirect, Route, Switch } from 'react-router-dom'
import { WindowContext } from '../contexts/WindowContext'
import '../styles/app.css'
import '../styles/transitions/transitions.css'
import NOT_FOUND from './404'
import HOME_PAGE from './Home'
import PROJECT_PAGE from './Project'
import { NavigatorContainer } from './styles'

const PAGES = React.memo(({ scrolled, scrollAmount, location }) => {
  const {
    windowContext: {
      orientation: { orientation },
      height,
      width
    }
  } = useContext(WindowContext)

  const route = location.pathname
  const search = location.search
  const params = search ? new URLSearchParams(search) : null

  return (
    <NavigatorContainer route={route}>
      <HelmetProvider>
        <Switch location={location}>
          <Route
            exact
            path='/'
            render={(props) => (
              <HOME_PAGE
                {...props}
                height={height}
                width={width}
                scrollAmount={scrollAmount}
                orientation={orientation}
                scrolled={scrolled}
                route={route}
              />
            )}
          />
          <Route
            exact
            path='/project/:id'
            render={(props) => (
              <PROJECT_PAGE
                {...props}
                route='/project'
                params={params}
                orientation={orientation}
                scrollAmount={scrollAmount}
                scrolled={scrolled}
                disableSnap
                height={height}
                width={width}
              />
            )}
          />
          <Route
            route={route}
            path='/404'
            render={(props) => <NOT_FOUND {...props} />}
          />
          <Route route={route} path='*' render={() => <Redirect to='/404' />} />
        </Switch>
      </HelmetProvider>
    </NavigatorContainer>
  )
})

export default PAGES
