import React from 'react'
import { useLocation } from 'react-router'
import CinketypeLogo from './CinketypeLogo'
import Styled from './styles'
import Anchor from './Anchor'

const Navbar = () => {
  const { pathname: route } = useLocation()
  const isValidRoute = route !== '/404'

  return (
    <Styled.NavBar>
      <nav>
        <CinketypeLogo />
        {isValidRoute && <Anchor />}
      </nav>
    </Styled.NavBar>
  )
}

export default Navbar
