import React, { useCallback, useEffect, useRef, useState } from 'react'
import CARD_MEDIA from './CardMedia'
import { Card } from './styles'

const CARD = React.memo(({ card, toggleLightbox, main, projecttype }) => {
  const [$loaded, setLoaded] = useState(false)

  const imageLoader = useCallback(() => {
    setLoaded(true)
  }, [])

  const refMain = useRef(null)

  useEffect(() => {
    if (main && refMain.current) {
      refMain.current.style.gridArea = 'mainCard'
    }
  }, [main])

  return (
    <Card
      half={card.half}
      projecttype={projecttype}
      main={main}
      ref={refMain}
      className='grid-item grid-square'
      onClick={toggleLightbox}
      id={card.id}
    >
      <CARD_MEDIA
        projecttype={projecttype}
        url=''
        $loaded={$loaded}
        imageLoader={imageLoader}
        card={card}
      />
    </Card>
  )
})

export default CARD
