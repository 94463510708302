import styled from 'styled-components/macro'
import { FlexCol, FlexRow, FlexRowEnd } from '../../styles/flex'

export const Footer = styled.footer`
  width: 100%;
  grid-area: footer;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "footerLeft footerRight";
  border-radius: 10px;

  @media (max-width: 1100px) {
    background-color: #000;
    grid-gap: var(--gap);
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    grid-template-areas:
      "footerRight footerRight"
      "footerLeft footerLeft";
  }
`

export const FooterLeft = styled.div`
  width: 100%;
  grid-area: footerLeft;
  height:100%;
  padding:calc(var(--global-padding1)  / 2) var(--global-padding1);
  ${FlexCol};
  align-items: flex-start;

  p{
    font-size: var(--footerLeft);
    line-height: 2em;
  }

  @media (max-width: 1100px) {
    padding: var(--global-padding2) var(--global-padding1) ;
    border-radius: 10px;
  }

`

export const FooterRight = styled.div`
  padding:calc(var(--global-padding1)  / 2) var(--global-padding1)  calc(var(--global-padding1)  / 2) 0 ;
  height:100%;
  width: 100%;
  grid-area: footerRight;

  @media (max-width: 1100px) {
    padding:var(--global-padding1) ;
  }

  display:grid;
  grid-template-columns: 1fr 1fr;

  div {
    display:grid;
    grid-template-columns: 1fr 1fr;
    ${FlexRowEnd};
    align-items: flex-start;
    width: 100%;
  }

  h3 {
    display: inline;
    text-align: left;
    width: auto;
    margin-right: var(--global-padding2);
  }
  
  ul {
    height:100%;
  }

  ul a li, ul li {
    list-style-type: none;
    font-size:var(--footerRight);
    line-height: 2em;
  }

  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
    grid-gap:calc(var(--global-padding1) / 2);
    border-radius: 10px;

    div {
      ${FlexRow};
      h3 {
        width:100%;
      }

      h3,ul{
        margin:0 20px;
        width:auto;
      }
    }
  }
  
  @media(max-width: 768px){
    padding-right:0;
    padding-left:0;
  }

`
