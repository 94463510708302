import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router'
import CARD_MEDIA from '../cards/CardMedia'
import { HomeCard } from '../cards/styles'

const HOME_CARD = React.memo(({ classes, card, id, gridArea, main, project }) => {
  const [$overflow, setOverflow] = useState(false)
  const [$loaded, setLoaded] = useState(false)

  const { meta: { name } } = project

  const toggleOverflow = useCallback(() => {
    setOverflow(!$overflow)
  }, [$overflow])

  const imageLoader = useCallback(() => {
    setLoaded(true)
  }, [])

  const refMain = useRef(null)

  useEffect(() => {
    if (main && refMain.current) {
      refMain.current.style.gridArea = 'mainCard'
    }
  }, [main])

  const route = useLocation().pathname

  const url = route === '/' ? `project/${name}` : `${name}`

  return (
    <HomeCard
      id={id}
      gridArea={gridArea}
      ref={refMain}
      className={classes}
      url={url}
    >
      <CARD_MEDIA
        projecttype={project.projectType}
        url={url}
        $loaded={$loaded}
        route={route}
        imageLoader={imageLoader}
        card={card}
        toggleOverflow={toggleOverflow}
      />
    </HomeCard>
  )
})

export default HOME_CARD
