import styled from 'styled-components/macro'
import { FlexCol, FlexRow, FlexRowEnd, FlexRowStart } from '../../styles/flex'

export const References = styled.div`
  width: 100%;
  grid-area: references;
  display: grid;
  grid-template-areas: "referencesQuote referencesQuote referencesQuote";
  ${FlexRow};
  position: relative;
  overflow: hidden;
  padding: 0 var(--global-padding1);
  border-radius: 10px;

  @media (max-width: 768px) {
    padding: var(--global-padding1);
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      "referencesQuote referencesQuote";
  }
`

export const RefText = styled.div`
  ${FlexRowStart};
  position: absolute;
  height: 100%;
  width: 100%;

  @media (max-width: 768px) {
    ${FlexCol};
  }
`

export const ReferencesQuote = styled.p`
  color: white;
  position: relative;
  width: 100%;
  height: auto;
  grid-area: referencesQuote;
  ${FlexRowEnd};
  padding-right: var(--global-padding3);
  font-size: var(--refQuote);
  line-height: 1.4em;

  @media (max-width: 768px) {
    ${FlexRowStart};
    padding: 0;
    margin-bottom: 15px;
  }


`

export const Source = styled.h5`
  color: white;
  text-align: right;
  width: 45%;
  text-transform: uppercase;
  font-weight: 400;
  font-size: var(--refSource);
  ${FlexRowEnd};
  letter-spacing: 0.05em;

  @media (orientation: landscape) {
    @media (max-width: 1440px) {
      ${FlexRowStart};
      width: 50%;
    }

    @media (max-width: 1200px) {
      ${FlexRowEnd};
      width: 70%;
    }
  }

  @media (orientation: portrait) {
    @media (max-width: 1200px ) {
      height: 100%;
      width: 80%;
      line-height: 1.5em;
    }

    @media (max-width: 768px) {
      ${FlexRowStart};
      height: auto;
      padding: 0;
      width: 100%;
      text-align: left;
    }
  }


`
