import React, { useContext, useEffect, useState } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { DatabaseContext } from '../../contexts/DatabaseContext'
import { WindowContext } from '../../contexts/WindowContext'
import '../../styles/transitions/c_horizontal.css'
import '../../styles/transitions/c_vertical.css'
import '../../styles/transitions/fade.css'
import { ReferencesQuote, RefText, Source } from './styles'

const REFERENECS_QUOTE = React.memo(() => {
  const {
    databaseContext: { references }
  } = useContext(DatabaseContext)

  const { windowContext: { orientation: { orientation } } } = useContext(WindowContext)

  const [state, setState] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      if (state < references.length - 1) {
        setState(() => state + 1)
      } else {
        setState(0)
      }
    }, 6000)

    return () => clearInterval(timer)
  })

  return (
    <TransitionGroup
      style={{ position: 'relative', width: '100%', height: '100%' }}
    >
      <CSSTransition
        classNames={
            orientation === 'landscape' ? 'c_vertical' : 'c_horizontal'
          }
        key={state}
        appear
        timeout={3000}
      >
        <RefText>
          <ReferencesQuote
            className='grid-item'
            reference={references[state]}
            route='/'
            length={references[state].quote.length}
          >
            {references[state].quote}
          </ReferencesQuote>
          <Source
            reference={references[state]}
            lineHeight={references[state]}
            fontSize={references[state]}
            route='/'
          >
            {references[state].source}
          </Source>
        </RefText>
      </CSSTransition>
    </TransitionGroup>

  )
})

export default REFERENECS_QUOTE
