import axios from 'axios'
import React, { useContext, useRef, useState } from 'react'
import { WindowContext } from '../../contexts/WindowContext'
import useMetaViewportUpdate from '../../hooks/useMetaViewportUpdate'
import validateField from './utils/validateField'
import ContactInput from './ContactInput'
import emailRegExp from './utils/emailRegExp'
import INPUT_FIELDS from './utils/inputFields'

import ContactSendButton from './ContactSendButton'
import Styled from './styles'

const ContactSection = () => {
  const {
    windowContext: {
      orientation: { orientation }
    }
  } = useContext(WindowContext)

  const formRef = useRef(null)

  const [sent, setSent] = useState('')

  useMetaViewportUpdate().onOrientationChange()

  const submitForm = async (e) => {
    e.preventDefault()
    const form = formRef.current
    const formData = new FormData(form)
    const formDataAll = formData.entries()
    const formObject = {}

    for (const element of formDataAll) {
      const key = element[0]
      const value = element[1]
      formObject[key] = value
    }
    const emailTest = formObject.email && emailRegExp.test(formObject.email)
    const invalidFields = Object.keys(formObject).filter((name) => {
      const value = formObject[name]
      const validationResult = validateField({ name, value }, orientation)
      return validationResult
    })

    const isValidationFailed = !emailTest || invalidFields.length > 0

    if (isValidationFailed) {
      setSent(false)
    } else {
      try {
        setSent('loading')
        await axios.post('/api/contact', formObject)

        setSent(true)
      } catch (err) {
        console.log(err)
        setSent(false)
      }
    }
  }

  const handleOnBlur = () => setSent('')

  return (
    <Styled.ContactForm
      data-testid='contact'
      id='contact'
      ref={formRef}
      name='form'
      onSubmit={submitForm}
    >
      <Styled.ContactFormGridArea className='grid-item'>
        <Styled.ContactTitle className='grid-item'>contact</Styled.ContactTitle>
        <Styled.ContactInputContainer>
          {INPUT_FIELDS.map((input) => (
            <ContactInput
              orientation={orientation}
              sent={sent}
              input={input}
              key={input.name}
            />
          ))}
        </Styled.ContactInputContainer>
      </Styled.ContactFormGridArea>
      <ContactSendButton
        orientation={orientation}
        sent={sent}
        onBlur={handleOnBlur}
      />
    </Styled.ContactForm>
  )
}

export default ContactSection
