import styled from 'styled-components/macro'
import { FlexRowBetween } from '../../styles/flex'

export const CheckOutOther = styled.div`
  padding: 0 var(--global-padding1);
  ${FlexRowBetween};
  width: 100%;
  grid-area: linkOther;
  border-radius: 10px;
  user-select: none;
  pointer-events: none;
`
