import React from 'react'
import LINKS from '../../utils/externalLinks'
import { BuyMe } from './styles'

const BUY_ME = () => {
  return (
    <BuyMe
      className='grid-item'
      target='_blank' href={LINKS.buyMe} rel='noreferrer'
    >
      <h2>buy me a coffee</h2>
      <h2>
        →
      </h2>
    </BuyMe>
  )
}

export default BUY_ME
