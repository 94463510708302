import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { Redirect } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { ThemeProvider } from 'styled-components/macro'
import AppNavigator from './components/AppNavigator'
import LOTTIE_PAGELOADER from './components/lottie/LottiePageLoader'
import { DatabaseContext } from './contexts/DatabaseContext'
import { ProjectContext } from './contexts/ProjectContext'
import { WindowContext } from './contexts/WindowContext'
import { ScreenContainer } from './screens/styles'
import './styles/app.css'
import theme from './styles/theme'
import './styles/transitions/transitions.css'
import './styles/transitions/transitions2.css'
import './styles/transitions/transitions3.css'
import projects from './assets/data/projects.json'
import references from './assets/data/references.json'

function App() {
  const {
    dispatch,
    windowContext: {
      $overflow,
      orientation: { orientation },
      height,
      width
    }
  } = useContext(WindowContext)

  const [isLoading, setIsLoading] = useState(true)

  const { dispatchData } = useContext(DatabaseContext)
  const { projectContext, dispatchProject } = useContext(ProjectContext)

  useEffect(() => {
    const ping = async () => {
      const response = await axios.get('/api/getProjects')
      console.log('@@@ ping', response)

      return null
    }

    ping()

    dispatchData({
      type: 'LOAD_PROJECTS',
      projects,
      references
    })
    dispatchProject({
      type: 'LOAD_PROJECT',
      value: projects[0]
    })
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }, [dispatchProject, dispatchData])

  useEffect(() => {
    const orientationchange = () => {
      dispatch({ type: 'ORIENTATION' })
    }
    window.addEventListener('orientationchange', orientationchange)
  }, [dispatch])

  useEffect(() => {
    const resize = () => {
      dispatch({ type: 'ORIENTATION' })
    }
    window.addEventListener('resize', resize)
  }, [dispatch])

  useEffect(() => {
    const metaViewport = document.querySelector('meta[name=viewport]')
    if (orientation === 'portrait') {
      metaViewport.setAttribute(
        'content',
        `width=${width}, height=${height}, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0`
      )
    } else {
      metaViewport.setAttribute(
        'content',
        'width=device-width, height=device-height, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0'
      )
    }
  }, [height, orientation, width])

  useEffect(() => {
    return () => {
      const metaViewport = document.querySelector('meta[name=viewport]')
      metaViewport.setAttribute(
        'content',
        'width=device-width, height=device-height, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0'
      )
    }
  }, [])

  const route = useLocation().pathname

  if (!projectContext) {
    return <Redirect to='/404' />
  }

  return (
    <ThemeProvider theme={theme(projectContext, isLoading)}>
      <TransitionGroup component={null}>
        <CSSTransition
          classNames={$overflow ? 'transition2' : 'transition3'}
          key={$overflow}
          appear
          timeout={1000}
        >
          <ScreenContainer route={route} $overflow='hidden'>
            <TransitionGroup>
              <CSSTransition
                classNames='transition'
                key={isLoading}
                appear
                timeout={1000}
              >
                <ScreenContainer route={route} $overflow='hidden'>
                  <>
                    {isLoading ? (
                      <LOTTIE_PAGELOADER />
                    ) : (
                      <AppNavigator route={route} />
                    )}
                  </>
                </ScreenContainer>
              </CSSTransition>
            </TransitionGroup>
          </ScreenContainer>
        </CSSTransition>
      </TransitionGroup>
    </ThemeProvider>
  )
}

export default App
