const INPUT_FIELDS = [
  {
    name: 'name',
    placeholder: 'your name'
  },
  {
    name: 'email',
    placeholder: 'e-mail'
  },
  {
    name: 'subject',
    placeholder: 'subject'
  },
  {
    name: 'message',
    placeholder: 'message'
  }
]

export default INPUT_FIELDS
