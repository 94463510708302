import React from 'react'
import { LinkSocial } from '../../styles/colorMixin'

const LINK_SOCIAL = React.memo(({ route, to, type = 'share' }) => {
  const icon = {
    facebook: '',
    twitter: '',
    instagram: '',
    share: ''
  }

  return (
    <>
      <LinkSocial target='_blank' route={route} href={to}>
        {icon[type]}
      </LinkSocial>
    </>
  )
})
export default LINK_SOCIAL
