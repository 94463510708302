import styled from 'styled-components/macro'
import { FlexCol, FlexColEnd } from '../../styles/flex'

export const About = styled.div`
  width: 100%;
  grid-area: about;
  display: grid;
  grid-template-rows: 560px;
  grid-template-areas: "aboutText aboutMonitor aboutSzt";
  grid-template-columns: repeat(3,1fr);
  overflow: hidden;
  border-radius: 10px;

  @media (max-width: 768px) {
  background-color: #000;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--gap-vertical);
      grid-template-rows: 325px auto;
    grid-template-areas:
      "aboutMonitor aboutSzt"
      "aboutText aboutText";
  }
`
export const AboutText = styled.div`
    ${FlexColEnd};
    color:white;
    width: 100%;
    padding: 0 var(--global-padding2) var(--global-padding1) var(--global-padding1);
    grid-area: aboutText;

    @media (max-width: 768px) {
        z-index: 4;
        padding: 0 var(--global-padding2) var(--global-padding2) var(--global-padding1);
        ${FlexCol};
        align-items: flex-start;
    }
`

export const AboutTitle = styled.h1`
  width: 100%;
`

export const AboutParagraph = styled.p`
  width: 100%;
  font-size: var(--p);
    max-width: 400px;
    line-height: 1.8em;
`

export const AboutMonitor = styled.div`
    position: relative;
    z-index: 2;
    ${FlexCol};
    grid-area: aboutMonitor;
`

export const AboutSzt = styled.div`
    background-color: transparent;
    width: 100%;
    height: 100%;
    grid-area: aboutSzt;
    position: relative;
    
    div {
        background-color: transparent;
    }
    


    @media (max-width: 768px) {
        
        transform:none
    }
`
