import React from 'react'
import { ProjectSummary } from './styles'

const PROJECT_SUMMARY = React.memo(({ summary }) => {
  return (
    <ProjectSummary className='grid-item'>
      <div>
        <h2>{summary.subtitle}</h2>
        <div>
          {summary.description.map((item, i) => (
            <p key={i}>{item}</p>
          ))}
        </div>
      </div>
    </ProjectSummary>
  )
})

export default PROJECT_SUMMARY
