import React from 'react'
import ABOUT from '../components/about/AboutSection'
import { ContactSection } from '../components/Contact'
import FOOTER from '../components/footer/Footer'
import HOME_PROJECTS_GRID from '../components/home/HomeProjectsGrid'
import { HomeGrid } from '../components/home/styles'
import META_HOME from '../components/meta/MetaHome'
import REFERENCES from '../components/references/ReferencesSection'
import SOCIAL_BAR from '../components/social/SocialBar'

const HOME_PAGE = () => {
  return (
    <>
      <META_HOME />
      <HomeGrid id='homeGrid'>
        <ABOUT />
        <REFERENCES />
        <HOME_PROJECTS_GRID />
        <SOCIAL_BAR />
        <ContactSection />
        <FOOTER />
      </HomeGrid>
    </>
  )
}

export default HOME_PAGE
