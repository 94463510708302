import React from 'react'
import LOTTIE_SZT from '../lottie/LottieSzt'
import { AboutSzt } from './styles'

const ABOUT_SZT = React.memo(() => {
  return (
    <AboutSzt className='grid-item'>
      <LOTTIE_SZT />
    </AboutSzt>
  )
})

export default ABOUT_SZT
