import React from 'react'
import ABOUT_MONITOR from './AboutMonitor'
import ABOUT_SZT from './AboutSzt'
import ABOUT_TEXT from './AboutText'
import { About } from './styles'

const ABOUT = () => {
  return (
    <About>
      <ABOUT_TEXT />
      <ABOUT_MONITOR />
      <ABOUT_SZT />
    </About>

  )
}

export default ABOUT
